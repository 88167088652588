
import Environment from '../constants/environment';
import { HttpLink, ApolloLink, ApolloClient, InMemoryCache, split } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
//import publicIP from 'react-native-public-ip';
import { APIErrorService } from '../services';

//const getPublicIp = () => {
//    return publicIP()
//    .then(ip => {
//        return ip;
//    })
//    .catch(error => {
//        // TODO: HANDLER.
//        return null;
//    });
//}

const IP_URLs = [
  'https://ipecho.net/plain',
  'https://api.ipify.org/',
  'https://ipecho.net/plain',
  'https://api.kwelo.com/v1/network/ip-address/my',
  'https://myexternalip.com/raw',
];

async function getIPFromNextSite(urls) {
  for (const url of urls) {
    try {
      const response = await fetch(url);
      const text = await response.text();
      if (isValidIP(text)) {
        // Send control flow to the consuming .then block:
        return text;
      }
    } catch(e) {
      // Don't do anything, just continue on to next iteration
    }
  }
  // Send control flow to the consuming .catch block:
  throw new Error('No more URLs');
}
function isValidIP(txt) {
  return /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(txt);
}
const getIP = urls => getIPFromNextSite(urls);
console.log('VALOR IP');
getIP(IP_URLs).then(console.log);


//const httpLink = new HttpLink({
//    'uri': Environment.apiUrl,
//    'fetchOptions': {
//        mode: 'cors',
//    }
//});



const httpLink = new HttpLink({
    'uri': Environment.apiUrl,
    'fetchOptions': {
        mode: 'cors',
    }
//    headers: {
//
//                'Access-Control-Allow-Origin': '*',
//
//            },



});
console.log(httpLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err['code']) {
                default:
                    APIErrorService.trowError('unknow');
            }
        }
    }

    if (networkError) {
        APIErrorService.trowError('unknow');
    }
});

const authMiddleware = setContext(async (operation, { headers }) => {
    const token = await localStorage.getItem('token');
    const ipAddress = await getIP(IP_URLs);
    return {
        headers: {
            ...headers,
            //'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            "Access-Control-Allow-Credentials" : true,
            'Access-Control-Allow-Origin': '*',
            authorization: token ? `Bearer ${token}` : '',
            'x-forwarded-for': ipAddress
        },
    };
});

const authLink = ApolloLink.from([ errorLink, authMiddleware, httpLink ]);

const wsLink = new WebSocketLink({
    uri: Environment.wsUrl,
    options: {
        reconnect: true,
    }
})
const operation = ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
};

const link = split(operation, wsLink, authLink);
const appCache = new InMemoryCache({addTypename: false})
const client = new ApolloClient({
    link: link,
    cache: appCache,

/*    queryDeduplication:
  defaultOptions: {
    watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all'
  },
},*/
});

export default client;
