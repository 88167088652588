import Reflux from 'reflux';

export const UserStoreActions = Reflux.createActions(['reset', 'update']);

export class UserStore extends Reflux.Store {
  state = {
    UserStore: {
      hasData: false,
      data: {},
    },
  };

  initialState = {};

  constructor() {
    super();
    this.listenables = UserStoreActions;
    this.initialState = this.state;
  }

  update(data) {
    let state = this.state;
    state.UserStore.data = data;
    state.UserStore.hasData = true;
    this.setState(state);
  }

  reset() {
    this.setState(this.initialState);
  }
}
