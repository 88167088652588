/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

interface HeaderInterface{
  title: string
}

class Header extends React.Component<HeaderInterface> {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 d-flex align-items-center"
          style={{
            minHeight: "200px",
            backgroundImage:
              //"url('+'/assets/img/theme/header-bg.jpg'+')",
              "url(" + require("../../assets/img/theme/header-bg.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-dark opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12" xs="12">
                <h1 className="display-2 text-white w-100">{this.props.title}</h1>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
